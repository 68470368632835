// Determines if "Talk to sales" CTA should appear
export const isRetailSalesAvailable = (locale: string) => {
  return [
    'en',
    'es',
    'en-GB',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-CA',
    'fr-CA',
    'de',
    'nl',
    'nl-BE',
    'fr-BE',
    'en-BE',
    'de-BE',
    'es-ES',
    'it',
    'da',
    'en-DK',
    'en-SG',
  ].includes(locale);
};

// Determines if 24/7 Support callouts should appear
export const isRetail247SupportAvailable = (locale: string) => {
  return [
    'en',
    'en-CA',
    'en-GB',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-BE',
    'en-SG',
    'en-DK',
  ].includes(locale);
};

// Determines if hardware and payments are available in the locale
export const isRetailHardwareAndPaymentsAvailable = (locale: string) => {
  return [
    'en',
    'en-CA',
    'en-BE',
    'en-GB',
    'fr-CA',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-SG',
    'en-DK',
    'da',
    'de',
    'es',
    'es-ES',
    'it',
    'nl',
    'nl-BE',
    'fr-BE',
    'de-BE',
  ].includes(locale);
};

// Determines if payments are available in the locale
export const isRetailPaymentsAvailable = (locale: string) => {
  return [
    'en',
    'en-CA',
    'en-BE',
    'en-GB',
    'fr-CA',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-SG',
    'en-DK',
    'da',
    'de',
    'es',
    'es-ES',
    'it',
    'nl',
    'nl-BE',
    'fr-BE',
    'de-BE',
  ].includes(locale);
};

// Show Sell Retail Case Study Video in these geos
export const isSellRetailVideoAvailable = (locale: string) => {
  return ['en', 'en-CA', 'en-GB', 'en-AU', 'en-IE'].includes(locale);
};

// Show Retail Demo Video in these geos
export const isRetailDemoVideoAvailable = (locale: string) => {
  return ['en', 'en-CA', 'en-GB', 'en-AU', 'en-IE'].includes(locale);
};

// Show POS Terminal in these geos
export const isRetailPosTerminalAvailable = (locale: string) => {
  return ['en', 'es', 'en-CA', 'fr-CA', 'en-GB', 'en-IE', 'en-AU'].includes(
    locale,
  );
};

// Show POS Go in these geos
export const isRetailPosGoAvailable = (locale: string) => {
  return ['en', 'es', 'en-CA', 'fr-CA', 'en-GB', 'en-IE', 'en-AU'].includes(
    locale,
  );
};

// Show Tap & Chip Card Reader in these geos
export const isRetailTapAndChipCardReaderAvailable = (locale: string) => {
  return ['en', 'es'].includes(locale);
};

// Show WisePad 3 Card Reader in these geos
export const isRetailWisePad3CardReaderAvailable = (locale: string) => {
  return [
    'en-CA',
    'en-BE',
    'en-GB',
    'fr-CA',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-SG',
    'en-DK',
    'da',
    'de',
    'es-ES',
    'it',
    'nl',
    'nl-BE',
    'fr-BE',
    'de-BE',
  ].includes(locale);
};

// Show Blog in these geos
export const isRetailBlogAvailable = (locale: string) => {
  return [
    'en',
    'en-CA',
    'en-GB',
    'en-IE',
    'en-AU',
    'en-NZ',
    'en-BE',
    'en-SG',
    'en-DK',
  ].includes(locale);
};

// Determines if core english markets should be shown
export const isRetailCoreEnglishMarketAvailable = (locale: string) => {
  return ['en', 'en-AU', 'en-CA', 'en-GB', 'en-IE', 'en-NZ'].includes(locale);
};

// Determines if core markets should be shown
export const isRetailCoreMarketAvailable = (locale: string) => {
  return [
    'en',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-IE',
    'en-NZ',
    'fr-CA',
    'es',
  ].includes(locale);
};

// Determines if POS Demo content is available
export const isPosDemoAvailable = (locale: string) => {
  return ['en'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, Discover, Interac, Apple Pay, and Google Pay
export const isCAHardwarePaymentAvailable = (locale: string) => {
  return ['en-CA', 'fr-CA'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, Discover, VPay, Maestro, Apple Pay, and Google Pay
export const isUKHardwarePaymentAvailable = (locale: string) => {
  return ['en-GB'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, VPay, Maestro, Apple Pay, and Google Pay
export const isIEHardwarePaymentAvailable = (locale: string) => {
  return ['en-IE'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, JCB, eftpos, Apple Pay, and Google Pay
export const isAUHardwarePaymentAvailable = (locale: string) => {
  return ['en-AU'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, Apple Pay, Google Pay, and JCB
export const isNZHardwarePaymentAvailable = (locale: string) => {
  return ['en-NZ'].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, VPay, Maestro, Apple Pay, and Google Pay
export const isEUHardwarePaymentAvailable = (locale: string) => {
  return [
    'en-DK',
    'da',
    'de',
    'de-BE',
    'en-BE',
    'fr-BE',
    'es-ES',
    'it',
    'nl',
    'nl-BE',
  ].includes(locale);
};

// Determines if Hardware accepts payment types: VISA, MasterCard, American Express, Apple Pay, and Google Pay
export const isSGHardwarePaymentAvailable = (locale: string) => {
  return ['en-SG'].includes(locale);
};
